/**
 * Importing necessary constants and utilities for the application.
 * These will be used across various modules and JavaScript files.
 */

import HashListener from "/WebInterface/new-ui/modules/hash-listener.js";
import {
  fileTypes,
  sortingOrder,
  sortingTypes,
  filesViewTypes,
  imageExtensions,
  audioExtensions,
  videExtensions,
  mimeTypes,
  mediaTypes,
} from "/WebInterface/new-ui/assets/js/constants.js";
import store from "/WebInterface/new-ui/store/index.js";
import { _$ } from "/WebInterface/new-ui/assets/js/utils/dom.js";
import resizableTemplate from "/WebInterface/new-ui/assets/js/app/templates/resizable.js";

const languagesWithCode = {
  en: "English",
  br: "Brazilian",
  bg: "Bulgarian",
  cn: "Chinese",
  cs: "Czech",
  da: "Danish",
  nl: "Dutch",
  fr: "French",
  de: "German:",
  hu: "Hungarian",
  it: "Italian",
  ko: "Korean",
  pl: "Polish",
  ro: "Romanian",
  ru: "Russian",
  sk: "Slovak",
  es: "Spanish",
  se: "Swedish",
};

const utilsFunc = (...args) => {
  const _cacheData = {};
  return {
    cache: {
      remove(key) {
        delete _cacheData[key];
      },
      exist(key) {
        return _cacheData.hasOwnProperty(key) && _cacheData[key] !== null;
      },
      get(key) {
        return _cacheData[key];
      },
      set(key, cachedData, callback) {
        _cacheData[key] = cachedData;
        if (callback) callback(cachedData);
      },
    },
    debounce: (callBack, wait) => {
      let timeoutId = null;

      return (...args) => {
        window.clearTimeout(timeoutId);

        timeoutId = window.setTimeout(() => {
          callBack.apply(null, args);
        }, wait);
      };
    },
    noop: () => {},
    deepCopy: (json) => json && JSON.parse(JSON.stringify(json)),
    storage: {
      set: (key, val) => localStorage.setItem(key, val),
      get: (key) => localStorage.getItem(key),
      remove: (key) => localStorage.removeItem(key),
    },
    encodeURILocal: (val) => {
      if (!val) return "";
      let _val = val;
      try {
        _val = encodeURIComponent(val);
      } catch (ex) {}
      return _val;
    },
    decodeURILocal(val) {
      let _val = val;
      try {
        _val = decodeURIComponent(val);
      } catch (ex) {}
      return _val;
    },
    sanitizePure(text) {
      if (!text) return "";
      if (typeof text !== "string") return "";
      return DOMPurify.sanitize(text, { ADD_ATTR: ["target", "style"] });
    },
    textEncode(text) {
      if (!text) return "";
      if (typeof text !== "string") return "";
      return text.replace(
        /[\u00A0-\u9999<>\&]/g,
        (i) => `&#${i.charCodeAt(0)};`
      );
    },
    getNameFromPath(path) {
      const normalizedPath = path.replace(/\/+$/, "");
      const parts = normalizedPath.split("/");
      return parts.pop();
    },
    cookie: {
      set: (key, value, options = {}) => {
        if (key && (value === null || typeof value !== "object")) {
          if (!value) {
            options.expires = "Thu, 01 Jan 1970 00:00:01 GMT;";
          }
          if (typeof options.expires === "number") {
            const days = options.expires;
            const t = (options.expires = new Date());
            t.setDate(t.getDate() + days);
            options.expires = t.toUTCString();
          }
          return (document.cookie = [
            utils.encodeURILocal(key),
            "=",
            options.raw ? String(value) : utils.encodeURILocal(String(value)),
            options.expires ? `; expires=${options.expires}` : "",
            // use expires attribute, max-age is not supported by IE
            options.path ? `; path=${options.path}` : "",
            options.domain ? `; domain=${options.domain}` : "",
            options.secure ? "; secure" : "",
          ].join(""));
        }
      },
      get: (key) => {
        let result = new RegExp(
          `(?:^|; )${utils.encodeURILocal(key)}=([^;]*)`
        ).exec(document.cookie);
        return result ? utils.decodeURILocal(result[1]) : null;
      },
      remove: (key) => {
        utils.cookie.set(key, "");
      },
    },
    fetchContent: (
      el = null,
      url = null,
      callback = () => {},
      cached = true
    ) => {
      let content;
      if (cached && utils.cache.exist(url)) {
        content = utils.cache.get(url);
        _$(el).html(content);
        callback();
      } else {
        request({
          url,
          success(resp) {
            _$(el).html(resp);
            callback();
          },
        });
      }
    },
    xmlToJson: (xml) => {
      let obj = {};

      if (xml.nodeType == 1) {
        // element
        // do attributes
        if (xml.attributes.length > 0) {
          obj["@attributes"] = {};
          for (let j = 0; j < xml.attributes.length; j++) {
            const attribute = xml.attributes.item(j);
            obj["@attributes"][attribute.nodeName] = attribute.nodeValue;
          }
        }
      } else if (xml.nodeType == 3) {
        // text
        obj = xml.nodeValue;
      }

      // do children
      // If all text nodes inside, get concatenated text from them.
      const textNodes = [].slice.call(xml.childNodes).filter(({ nodeType }) => {
        return nodeType === 3;
      });
      if (xml.hasChildNodes() && xml.childNodes.length === textNodes.length) {
        obj = [].slice.call(xml.childNodes).reduce((text, { nodeValue }) => {
          return text + nodeValue;
        }, "");
      } else if (xml.hasChildNodes()) {
        for (let i = 0; i < xml.childNodes.length; i++) {
          const item = xml.childNodes.item(i);
          const nodeName = item.nodeName;
          if (typeof obj[nodeName] === "undefined") {
            obj[nodeName] = utils.xmlToJson(item);
          } else {
            if (typeof obj[nodeName].push === "undefined") {
              const old = obj[nodeName];
              obj[nodeName] = [];
              obj[nodeName].push(old);
            }
            obj[nodeName].push(utils.xmlToJson(item));
          }
        }
      }
      if (typeof obj === "object" && Object.keys(obj).length === 0) return "";
      else return obj;
    },

    /**
     * Sanitizes an input string by removing HTML tags and replacing '<' with '&lt;'.
     * @param {string} str - The input string to sanitize.
     * @returns {string} The sanitized string.
     */
    sanitize: (str) => {
      if (!str) return str;
      const tagBody = "(?:[^\"'>]|\"[^\"]*\"|'[^']*')*";
      const tagOrComment = new RegExp(
        // Comment body.
        // Special "raw text" elements whose content should be elided.
        // Regular name.
        `<(?:!--(?:(?:-*[^->])*--+|-?)|script\\b${tagBody}>[\\s\\S]*?</script\\s*|style\\b${tagBody}>[\\s\\S]*?</style\\s*|/?[a-z]${tagBody})>`,
        "gi"
      );

      function removeTags(html) {
        let oldHtml;
        do {
          oldHtml = html;
          html = html.replace(tagOrComment, "");
        } while (html !== oldHtml);
        return html.replace(/</g, "&lt;");
      }

      return removeTags(str);
    },

    /**
     * Retrieves the value of a query parameter from the URL.
     * @param {string} name - The name of the query parameter.
     * @returns {string|boolean} The value of the query parameter if found, or false if not found.
     */
    queryString: (name) => {
      if (!name) return false;
      const sanitizedName = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
      const regexS = `[\\?&]${sanitizedName}=([^&#]*)`;
      const regex = new RegExp(regexS);
      const results = regex.exec(window.location.search);

      if (results == null) {
        return "";
      } else {
        const decodedValue = decodeURIComponent(results[1].replace(/\+/g, " "));
        return utils.sanitize(decodedValue, true);
      }
    },

    /**
     * Retrieves the URL query parameters as an object.
     * @returns {Object} An object representing the URL query parameters.
     */
    URLVars: () => {
      const vars = {};
      const hashes = window.location.href
        .slice(window.location.href.indexOf("?") + 1)
        .split("&");

      for (let i = 0; i < hashes.length; i++) {
        const hash = hashes[i].split("=");
        if (hash[0] && hash[1]) {
          const key = hash[0];
          const value = utils.sanitize(
            decodeURIComponent(hash[1].replace(/\+/g, " ")),
            true
          );
          vars[key] = value;
        }
      }

      return vars;
    },

    /**
     * Dynamically imports a module from the specified URL.
     *
     * @param {string} url - The URL of the module to import.
     * @returns {Promise<any>} Returns a promise that resolves to the default export of the imported module.
     */
    importModule: async (url) => {
      const module = await import(url);
      return module;
    },

    /**
     * Sends an event to Electron if running in the CrushShare app.
     * @param {string} type - The type of the event to send.
     * @param {any} data - The data to send along with the event.
     */
    electronEvent: (type, data) => {
      if (
        window.isElectron &&
        window.isCrushShare &&
        window.isElectron() &&
        window.isCrushShare()
      ) {
        const IPCRenderer = window.getIPCRenderer();
        if (IPCRenderer) {
          IPCRenderer.send(type, data);
        }
      }
    },

    /**
     * Retrieves the MIME type based on the file extension.
     * @param {string} fileName - The name of the file.
     * @returns {string|null} The MIME type corresponding to the file extension, or null if it is not recognized.
     */
    getMimetype: (fileName) => {
      const fileType = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toLowerCase();

      const imageExtensions = ["jpg", "png", "jpeg", "gif", "bmp"];
      const htmlExtensions = ["html", "htm", "dhtml", "exif"];
      const videoExtensions = ["mp4", "mov", "mkv", "avi"];
      const xmlExtensions = ["rss", "xml", "xsl"];
      const plainExtensions = ["txt", "log"];
      const javaArchiveExtensions = ["jar"];

      if (imageExtensions.includes(fileType)) {
        return "image";
      } else if (htmlExtensions.includes(fileType)) {
        return "html";
      } else if (videoExtensions.includes(fileType)) {
        return "video";
      } else if (xmlExtensions.includes(fileType)) {
        return "xml";
      } else if (fileType === "css") {
        return "css";
      } else if (plainExtensions.includes(fileType)) {
        return "plain";
      } else if (javaArchiveExtensions.includes(fileType)) {
        return "java-archive";
      } else {
        return null;
      }
    },

    availableFileExtensionImages: [
      "ai",
      "avi",
      "bak",
      "bat",
      "bin",
      "bmp",
      "cab",
      "cmd",
      "css",
      "csv",
      "cue",
      "dat",
      "dic",
      "divx",
      "dll",
      "dmg",
      "doc",
      "docx",
      "dvd",
      "dwg",
      "exe",
      "file",
      "fla",
      "gif",
      "htm",
      "html",
      "ifo",
      "ini",
      "iso",
      "jpeg",
      "jpg",
      "js",
      "m4a",
      "mmf",
      "mov",
      "mp3",
      "mp4",
      "m4v",
      "h264",
      "mpeg",
      "mpg",
      "otf",
      "pdf",
      "php",
      "png",
      "pps",
      "ppt",
      "pptx",
      "psd",
      "rar",
      "rtf",
      "swf",
      "sys",
      "tiff",
      "ttf",
      "txt",
      "vob",
      "wma",
      "wmv",
      "xls",
      "xlsx",
      "xml",
      "xmp",
      "zip",
      "sitx",
      "idml",
      "indd",
      "sit",
    ],

    /**
     * Retrieves the file extension from the provided item.
     * @param {File} item - The file item.
     * @returns {string|boolean} The file extension if it is a supported image extension, or false if it is not.
     */
    getFileExtension: (item) => {
      const fileName = typeof item === "string" ? item : item?.name;
      const dotIndex = fileName.lastIndexOf(".");
      if (dotIndex > 0 && dotIndex < fileName.length - 1) {
        return fileName.substring(dotIndex + 1).toLowerCase();
      } else {
        return "";
      }
    },
    isImageExtension: (item) => {
      const extName = utils.getFileExtension(item?.name);
      return utils.availableFileExtensionImages.includes(extName)
        ? extName
        : false;
    },
    isAudioExtension: (item) => {
      const extName = utils.getFileExtension(item?.name);
      const supportedAudioExtensions = ["mp3", "wav", "ogg", "aac", "m4a"];
      return supportedAudioExtensions.includes(extName.toLowerCase());
    },

    /**
     * Retrieves the name of the current browser based on the user agent.
     * @returns {string} The name of the browser (e.g., "chrome", "firefox", "safari", "opera", "edge"), or an empty string if the browser name cannot be determined.
     */
    getBrowserName: () => {
      /**
       * The user agent string of the current browser.
       * @type {string}
       */
      const userAgent = navigator.userAgent.toLowerCase();

      /**
       * Object mapping browser names to regular expressions for matching the user agent.
       * @type {Object.<string, RegExp>}
       */
      const browserNames = {
        chrome: /chrome|chromium|crios/,
        firefox: /firefox|fxios/,
        safari: /safari/,
        opera: /opr\//,
        edge: /edg/,
      };

      for (const browser in browserNames) {
        if (userAgent.match(browserNames[browser])) {
          return browser;
        }
      }

      return "";
    },

    /**
     * Generates a unique identifier.
     *
     * @returns {string} The unique identifier.
     */
    getUId: () => {
      const timestamp = Date.now().toString(36); // Convert current time to base36 string
      const randomChars = Math.random().toString(36).slice(2); // Generate random characters

      return `${timestamp}${randomChars}`;
    },

    /**
     * Formats time in minutes and seconds.
     *
     * @param {Object} options - The options for formatting the time.
     * @param {number} options.secs - The number of seconds.
     * @param {string} [options.minLabel="min"] - The label for minutes.
     * @param {string} [options.secondsLabel="secs"] - The label for seconds.
     * @returns {string} The formatted time.
     */
    formatTime: ({ secs = 0, minLabel = "min", secondsLabel = "secs" }) => {
      secs = Math.floor(Number(secs));
      const mins = Math.floor(secs / 60);
      const remainingSecs = secs % 60;

      let remaining = "";
      if (mins > 0) {
        remaining =
          mins + "" + minLabel + ", " + remainingSecs + "" + secondsLabel;
      } else {
        if (remainingSecs < 0) {
          remaining = "0" + secondsLabel;
        } else {
          remaining = remainingSecs + "" + secondsLabel;
        }
      }

      return remaining;
    },

    /**
     * Executes a callback function for each object in an array.
     *
     * @param {Array} objects - The array of objects.
     * @param {Function} callback - The callback function to execute for each object.
     */
    each: (objects, callback) => {
      objects.forEach((object, index) => {
        callback(object, index);
      });
    },

    /**
     * Creates an HTML element with the specified attributes, classes, and content.
     *
     * @param {string} name - The name of the HTML element to create.
     * @param {string|string[]} classes - The class(es) to add to the element.
     * @param {Object} attributes - The attributes to set on the element.
     * @param {string} html - The HTML content to set inside the element.
     * @param {string} text - The text content to append to the element.
     * @returns {HTMLElement} The created HTML element.
     */
    createElement: (name, classes, attributes, html, text) => {
      classes = classes || [];
      classes = typeof classes === "string" ? [classes] : classes;

      const elem = document.createElement(name);

      classes.forEach((className) => {
        if (className) {
          elem.classList.add(className);
        }
      });

      for (const attr in attributes) {
        if (attributes.hasOwnProperty(attr)) {
          elem.setAttribute(attr, attributes[attr]);

          if (name.toLowerCase() === "textarea" && attr === "value") {
            elem.innerHTML = attributes[attr];
          }
        }
      }

      if (html) {
        elem.innerHTML = html;
      }

      if (text) {
        elem.appendChild(document.createTextNode(text));
      }

      return elem;
    },

    /**
     * Finds or creates an HTML element based on the specified query.
     *
     * @param {string} query - The query string used to select the element.
     * @param {HTMLElement} [parent=document] - The parent element to search within. Defaults to the document.
     * @param {string} [elemName="div"] - The name of the element to create if it doesn't exist.
     * @param {string[]} [classes=[]] - An array of classes to add to the created element.
     * @returns {HTMLElement} - The found or created element.
     */
    findOrCreateElement: (
      query,
      parent = document,
      elemName = "div",
      classes = []
    ) => {
      let elem = parent.querySelector(query);

      if (!elem) {
        classes =
          classes.length === 0 ? query.split(".").filter(Boolean) : classes;
        elem = utils.createElement(elemName, classes);
        parent.appendChild(elem);
      }

      return elem;
    },

    /**
     * Retrieves a matched string from the given value based on the provided regular expression.
     *
     * @param {RegExp} regExp - The regular expression to match against the value.
     * @param {string} value - The string value to search for a match.
     * @param {number} [index=0] - The index of the matched value to return. Defaults to 0.
     * @returns {string} - The matched string, or an empty string if no match is found.
     */
    getMatch: (regExp, value, index = 0) => {
      const match = regExp.exec(value);
      return match && match.length > index ? match[index].trim() : "";
    },

    /**
     * Generates HTML elements based on the provided object.
     *
     * @param {Array} obj - The array of objects representing HTML elements.
     * @param {HTMLElement} [rootNode=document.createElement("div")] - The root HTML element to append the generated elements to.
     * @returns {HTMLElement} - The root HTML element with the generated elements appended.
     */
    generateHTML: (obj = [], rootNode = document.createElement("div")) => {
      obj.forEach((curElem) => {
        const keyParts = curElem.type.split(".");
        const elemTag = keyParts[0].match(/^[^#.@>[]+/)[0];
        const elemName = (keyParts[0].match(/[@][^#.@>[]+/) || [""])[0].replace(
          /^[\s@]+/,
          ""
        );
        const elemID = (keyParts[0].match(/[#][^#.@>[]+/) || [""])[0].replace(
          /^[\s#]+/,
          ""
        );
        const classes = keyParts.slice(1);
        const attrs = { ...(curElem.attrs || curElem.attributes) };
        if (elemName) attrs.name ||= elemName;
        if (elemID) attrs.id ||= elemID;
        const html = curElem.html ? utils.sanitizePure(curElem.html) : null;
        const text = curElem.text || null;
        const textAfter = curElem.textAfter || null;
        const currentNode = utils.createElement(
          elemTag,
          classes,
          attrs,
          html,
          text
        );
        if (curElem.children) {
          utils.generateHTML(curElem.children, currentNode);
        }
        if (textAfter) {
          currentNode.appendChild(document.createTextNode(textAfter));
        }
        rootNode.appendChild(currentNode);
      });
      return rootNode;
    },

    /**
     * Returns the index of the element relative to its siblings.
     * @param {HTMLElement} node - The element node.
     * @returns {number|null} - The index of the element, or null if the node is falsy.
     */
    elementIndex: (node) => {
      if (node) {
        let index = 0;
        while ((node = node.previousElementSibling)) {
          index++;
        }
        return index;
      } else {
        return null;
      }
    },

    /**
     * Checks if the input is numeric.
     * @param {*} input - The input value to check.
     * @returns {boolean} - True if the input is numeric, false otherwise.
     */
    isNumeric: (input) => {
      return (
        typeof input === "number" || (input - 0 == input && input.length > 0)
      );
    },

    /**
     * Resets the privileges based on the given privs.
     * @param {string} privs - The privileges to reset.
     * @returns {string} - The reset privileges.
     */
    resetPrivs: (privs) => {
      if (privs.includes("locked") && !privs.includes("inherited")) {
        return privs.replace(
          /\((write|delete|deletedir|makedir|rename|resume)\)/g,
          ""
        );
      }
      return privs;
    },

    /**
     * Clears the range selection in the current document.
     */
    clearRangeSelection: () => {
      if (window.getSelection) {
        if (window.getSelection().empty) {
          window.getSelection().empty();
        } else if (window.getSelection().removeAllRanges) {
          window.getSelection().removeAllRanges();
        }
      } else if (document.selection) {
        document.selection.empty();
      }
    },

    /**
     * Arranges files and folders based on the current path.
     * @param {Array} fileAndFolderList - The list of files and folders to arrange.
     * @returns {Array} - The arranged files and folders.
     */
    arrangeFilesAndFolder: (fileAndFolderList = []) => {
      const hashListener = new HashListener({});
      const curPath = hashListener.getPath();

      let filteredParentFileFolder;
      let arrangedFilesAndFolder = [];

      filteredParentFileFolder = fileAndFolderList.filter(
        (item) => decodeURIComponent(item.root_dir) === curPath
      );

      filteredParentFileFolder.forEach((file) => {
        arrangedFilesAndFolder.push(file);
        let childrenFiles = [];

        if (file.ariaExpanded) {
          const findChildrenOfChildren = (fileToBeCheck) => {
            fileAndFolderList.map((item) => {
              if (item.root_dir === fileToBeCheck.href_path + "/") {
                childrenFiles.push(item);
                if (item.ariaExpanded) {
                  findChildrenOfChildren(item);
                }
              }
            });
          };

          findChildrenOfChildren(file);
        }

        childrenFiles.forEach((item) => {
          arrangedFilesAndFolder.push(item);
        });
      });

      return arrangedFilesAndFolder;
    },

    /**
     * Sorts the file listing based on the specified sorting type, field name, and order.
     * @param {Object} data - The data object containing the payload.
     * @param {Object} data.payload - The payload object containing sorting information.
     * @param {Array} data.payload.files - The array of files to be sorted.
     * @param {string} data.payload.type - The type of sorting (e.g., "STRING", "NUMBER", "DATE").
     * @param {string} data.payload.fieldName - The name of the field to sort by.
     * @param {string} data.payload.order - The sorting order (e.g., "ASC", "DES").
     * @returns {Array} - The sorted file listing.
     */
    sortListing: (data = { payload: {} }) => {
      const { payload } = data;
      let newList = [];
      const _files = payload?.files || store?.files || [];
      const alphaNumeric = payload?.alphaNumeric;

      switch (payload.type) {
        case sortingTypes.STRING:
          // Sort by string field
          const stringSorting = (files) => {
            files.sort((a, b) => {
              if (payload.order === sortingOrder.ASC) {
                return a[payload.fieldName]
                  .toLowerCase()
                  .localeCompare(
                    b[payload.fieldName].toLowerCase(),
                    undefined,
                    { numeric: alphaNumeric, sensitivity: "base" }
                  );
              } else if (payload.order === sortingOrder.DES) {
                return b[payload.fieldName]
                  .toLowerCase()
                  .localeCompare(
                    a[payload.fieldName].toLowerCase(),
                    undefined,
                    { numeric: alphaNumeric, sensitivity: "base" }
                  );
              }
            });
            return _files;
          };

          newList = stringSorting(_files);
          break;

        case sortingTypes.NUMBER:
          // Sort by numeric field
          const numberSorting = (files) => {
            files.sort((a, b) => {
              if (payload.order === sortingOrder.ASC) {
                return (
                  Number(a[payload.fieldName]) - Number(b[payload.fieldName])
                );
              } else if (payload.order === sortingOrder.DES) {
                return (
                  Number(b[payload.fieldName]) - Number(a[payload.fieldName])
                );
              }
            });
            return _files;
          };

          newList = numberSorting(_files);
          break;

        case sortingTypes.DATE:
          // Sort by date field
          const dateSorting = (files) => {
            files.sort((a, b) => {
              const date1 = new Date(Number(a[payload.fieldName]));
              const date2 = new Date(Number(b[payload.fieldName]));

              if (payload.order === sortingOrder.ASC) {
                return date1 - date2;
              } else if (payload.order === sortingOrder.DES) {
                return date2 - date1;
              }
            });
            return _files;
          };

          newList = dateSorting(_files);
          break;

        default:
          break;
      }

      return [...newList];
    },

    /**
     * Moves directory items to the top of the file list.
     * @param {Array} files - The array of files.
     * @returns {Array} - The new sorted file list with directory items on top.
     */
    keepDirectoryOnTop: (files) => {
      const _files = [...files];
      const dirItems = [];
      const fileItems = [];

      if (_files) {
        for (let i = 0; i < _files.length; i++) {
          const type = _files[i].type;
          if (typeof type !== "undefined" && type === fileTypes.DIR) {
            dirItems.push(_files[i]);
          } else {
            fileItems.push(_files[i]);
          }
        }
      }

      const newList = [...dirItems, ...fileItems];
      return newList;
    },

    /**
     * Renders the sort icon based on the sorting type.
     * @param {Array} sortingTypes - The array of sorting types.
     */
    renderSortIconFromID: (sortingTypes) => {
      sortingTypes.forEach((sortingType) => {
        let icon;
        const sortingIconCommonClasses = ["sorting-icon", "text-accentMain"];

        if (!sortingType.show) {
          const parentElement = _$(`#${sortingType.id}`);
          parentElement.find(".sorting-icon").remove();
          return;
        }

        if (sortingType.type === sortingOrder.DES) {
          icon = utils.createElement("i", [
            ...sortingIconCommonClasses,
            "icon-sort-up",
          ]);
        } else {
          icon = utils.createElement("i", [
            ...sortingIconCommonClasses,
            "icon-sort-down",
          ]);
        }

        const parentElement = _$(`#${sortingType.id}`).el();

        if (parentElement) {
          _$(parentElement).find(".sorting-icon").remove();
          parentElement.append(icon);
        }
      });
    },

    /**
     * Applies options like sorting and directory ordering to the file list.
     * @param {Array} fileList - The original file list.
     * @returns {Array} - The modified file list with applied options.
     */
    checkOptionsAndSort: (fileList, notArrange = false) => {
      let files = utils.deepCopy(fileList);
      let listSortingAlphaNumeric = utils.getUserCustomizationValue(
        "listSortingAlphaNumeric"
      );

      const isKeepDirectoryOnTop = JSON.parse(
        utils.storage.get("keepDirectoryOnTop")
      );

      const sortingStatus = JSON.parse(utils.storage.get("sortingStatus"));

      if (sortingStatus) {
        const activeFieldName = sortingStatus.active;
        const sortingType = sortingStatus[activeFieldName];

        files = utils.sortListing({
          payload: {
            fieldName: activeFieldName,
            order: sortingType,
            type:
              activeFieldName === "modified" || activeFieldName === "created"
                ? sortingTypes.DATE
                : activeFieldName === "size"
                ? sortingTypes.NUMBER
                : sortingTypes.STRING,
            files,
            alphaNumeric: listSortingAlphaNumeric,
          },
        });
      }

      if (isKeepDirectoryOnTop) {
        files = utils.keepDirectoryOnTop(files);
      }

      if (!store.searchFilterDetails.isSearchFilterActive && !notArrange) {
        return utils.arrangeFilesAndFolder(files);
      }

      return files;
    },

    /**
     * Retrieves the comment from the current permission based on the specified value.
     *
     * @param {string} currentPerm - The current permission string.
     * @param {string} val - The value to search for in the permission string.
     * @returns {boolean|string} Returns false if no comment is found, otherwise returns the comment string.
     */
    getPermissionData: (currentPerm, val) => {
      let comment = false;

      if (currentPerm && val && val.length > 0) {
        val = "(" + val;
        const commentIndex = currentPerm.indexOf(val);

        if (commentIndex >= 0) {
          comment = currentPerm.substring(
            commentIndex + val.length,
            currentPerm.indexOf(")", commentIndex)
          );
        }
      }

      return comment;
    },

    /**
     * Retrieves the selected items from an array of files based on the specified conditions.
     *
     * @param {Array} files - The array of files.
     * @param {string} curPath - The current path.
     * @param {boolean} first - Indicates whether to return the first selected item only.
     * @returns {Array} Returns the selected items based on the conditions.
     */
    getSelectedItems: (files, curPath = "/", first) => {
      const activeView = utils.storage.get("activeView");
      const methodToUse = first ? "find" : "filter";
      const isSearchFilterActive =
        store?.searchFilterDetails?.isSearchFilterActive;

      if (activeView !== filesViewTypes.LIST) {
        return files[methodToUse](
          (item) =>
            item.isChecked === true &&
            (curPath === "BASKET" ||
              item.root_dir === curPath ||
              isSearchFilterActive)
        );
      } else {
        return files[methodToUse]((item) => item.isChecked === true);
      }
    },

    /**
     * Retrieves the offset top position of an element.
     *
     * @param {HTMLElement} element - The element to get the offset top position from.
     * @param {boolean} canCheckOffsetParent - Indicates whether to check the offset parent while calculating the offset top.
     * @returns {number} Returns the offset top position of the element.
     */
    getOffsetTop: (element, canCheckOffsetParent) => {
      let offsetTop = 0;

      if (canCheckOffsetParent) {
        while (element) {
          offsetTop += element.offsetTop;
          element = element.offsetParent;
        }
      } else {
        offsetTop = element.offsetTop;
      }

      return offsetTop;
    },

    /**
     * Calculates the height that fits within the viewport for a container element.
     *
     * @param {HTMLElement} container - The container element.
     * @param {number} vpHeight - The height of the viewport.
     * @param {number} bottomMargin - The bottom margin to subtract from the viewport height.
     * @param {boolean} canCheckOffsetParent - Indicates whether to check the offset parent while calculating the offset top of the container.
     * @returns {string} Returns the calculated height as a string with "px" unit.
     */
    getFitHeight: (
      container,
      vpHeight = window.innerHeight,
      bottomMargin = 50,
      canCheckOffsetParent = true
    ) => {
      const top = utils.getOffsetTop(container, canCheckOffsetParent);

      if (top) {
        const newHeight = vpHeight - top - bottomMargin;
        return `${newHeight}px`;
      }

      return "";
    },

    /**
     * Determines the media type and MIME type of a file based on its extension.
     *
     * @param {string} fileName - The name of the file.
     * @returns {boolean|object} Returns false if the file extension is not recognized as media, otherwise returns an object with the media type and MIME type.
     */
    isMedia: (fileName) => {
      const ext = fileName
        .substring(fileName.lastIndexOf(".") + 1)
        .toLowerCase();

      if (imageExtensions.indexOf(ext) >= 0) {
        return {
          type: mediaTypes.IMAGE,
          mimeType: mimeTypes[ext] || `image/${ext}`,
        };
      } else if (audioExtensions.indexOf(ext) >= 0) {
        return {
          type: mediaTypes.AUDIO,
          mimeType: mimeTypes[ext] || `audio/${ext}`,
        };
      } else if (videExtensions.indexOf(ext) >= 0) {
        return {
          type: mediaTypes.VIDEO,
          mimeType: mimeTypes[ext] || `video/${ext}`,
        };
      } else {
        return false;
      }
    },

    /**
     * Calculates the position of a popover element relative to a reference element within a parent element.
     *
     * @param {HTMLElement} relativeElement - The reference element.
     * @param {HTMLElement} absoluteElement - The popover element.
     * @param {HTMLElement} parent - The parent element.
     * @returns {object} Returns an object with the left and bottom CSS properties for positioning the popover element.
     */
    getPopoverPositions: (
      relativeElement,
      absoluteElement,
      parent = document.body
    ) => {
      const {
        left: relativeElementLeft,
        right: relativeElementRight,
        bottom: relativeElementBottom,
      } = relativeElement.getBoundingClientRect();

      const { left, right, bottom } = parent.getBoundingClientRect();

      const absoluteElementWidth = absoluteElement.clientWidth;
      const absoluteElementHeight = absoluteElement.clientHeight;

      const leftDifference = relativeElementLeft - left;
      const rightDifference = right - relativeElementRight;
      const bottomDifference = bottom - relativeElementBottom;

      let positionObject = { left: `${-leftDifference + 5}px`, right: "auto" };

      if (bottomDifference < absoluteElementHeight) {
        positionObject = { ...positionObject, bottom: 0 };
      }

      if (rightDifference > absoluteElementWidth) {
        return { ...positionObject, left: 0, right: "auto" };
      }

      if (leftDifference > absoluteElementWidth) {
        return { ...positionObject, right: 0, left: "auto" };
      }

      return positionObject;
    },

    /**
     * Filters an array of files based on the search value.
     *
     * @param {Array} fileList - The array of files.
     * @param {string} searchValue - The search value.
     * @returns {Array} Returns the filtered array of files.
     */
    filterFiles: (fileList, searchValue) => {
      return fileList.filter((file) => {
        if (file.name.toLowerCase().includes(searchValue.toLowerCase())) {
          return true;
        }
        //Temporary disabled
        // if (
        //   file.sizeFormatted.toLowerCase().includes(searchValue.toLowerCase())
        // ) {
        //   return true;
        // }
        // if (
        //   file.dateFormatted.toLowerCase().includes(searchValue.toLowerCase())
        // ) {
        //   return true;
        // }
        // if (file.keywords.toLowerCase().includes(searchValue.toLowerCase())) {
        //   return true;
        // }
        return false;
      });
    },

    /**
     * Filters an array of files based on the modified date within the specified number of days.
     *
     * @param {Array} files - The array of file information objects.
     * @param {number} day - The number of days to filter files by.
     * @returns {Array} Returns the filtered array of file information objects with an added "isChecked" property.
     */
    filterModifiedFilesByDay: (files, day) => {
      return files.map((fileInfo) => {
        const isConditionChecked =
          fileInfo.modified * 1 >
          new Date().getTime() - 1000 * 60 * 60 * 24 * day;

        return {
          ...fileInfo,
          isChecked: isConditionChecked,
        };
      });
    },

    /**
     * Checks if the key pressed in an event corresponds to a letter or the Backspace key.
     *
     * @param {Event} e - The keyboard event object.
     * @returns {boolean} Returns true if the key is a letter or Backspace, false otherwise.
     */
    lettersOnlyFromEvent: (e) => {
      const charCode = e.keyCode;

      if (
        (charCode > 47 && charCode < 58) || // Numeric (0-9)
        (charCode > 64 && charCode < 91) || // Uppercase letters (A-Z)
        (charCode > 96 && charCode < 123) || // Lowercase letters (a-z)
        (charCode > 32 && charCode < 48) || // Special characters before '0'
        (charCode > 57 && charCode < 65) || // Special characters between '9' and 'A'
        (charCode > 90 && charCode < 97) || // Special characters between 'Z' and 'a'
        (charCode > 122 && charCode < 127) // Special characters after 'z'
      ) {
        return true;
      }

      return false;
    },

    /**
     * Binds data from a JSON object to elements within a parent element using data-key attributes.
     *
     * @param {object} data - The JSON object containing the data to bind.
     * @param {HTMLElement} parent - The parent element to bind the data within.
     */
    bindDataFromJSON: (data, parent) => {
      let elements = parent._boundElements;

      if (!elements) {
        elements = parent.querySelectorAll("[data-key]");
        parent._boundElements = elements;
      }

      const fragment = document.createDocumentFragment();
      const length = elements.length;

      for (let i = 0; i < length; i++) {
        const element = elements[i];
        const key = element.getAttribute("data-key");
        const value = utils.getObjectValueByKey(data, key);

        if (value !== undefined) {
          switch (element.tagName.toLowerCase()) {
            case "input":
              switch (element.type) {
                case "checkbox":
                  element.checked = value;
                  break;
                case "radio":
                  if (element.value === value.toString()) {
                    element.checked = true;
                  }
                  break;
                default:
                  element.value = value;
              }
              break;
            default:
              element.textContent = value;
          }

          fragment.appendChild(element);
        }
      }

      parent.appendChild(fragment);
    },

    /**
     * Retrieves the value of an object property based on the key.
     *
     * @param {object} object - The object to retrieve the value from.
     * @param {string} key - The key to access the property (supports dot notation).
     * @returns {*} Returns the value of the property or undefined if not found.
     */
    getObjectValueByKey: (object, key) => {
      const keys = key.split(".");
      let value = object;

      for (let i = 0; i < keys.length; i++) {
        if (value[keys[i]] !== undefined) {
          value = value[keys[i]];
        } else {
          value = undefined;
          break;
        }
      }

      return value;
    },

    /**
     * Serializes input elements within a parent element into a data object.
     *
     * @param {HTMLElement} parent - The parent element containing the input elements.
     * @param {boolean} isFormData - Flag indicating whether to return a FormData object (default: false).
     * @param {boolean} onlyVisible - Flag indicating whether to include only visible input elements (default: false).
     * @returns {FormData|object} Returns the serialized data object.
     */
    serializeInputs: (parent, isFormData = false, onlyVisible = false) => {
      const inputElements = parent.querySelectorAll("input, select, textarea");
      const inputs = onlyVisible
        ? utils.filterVisible(inputElements)
        : inputElements;

      let data;

      if (isFormData) {
        data = new FormData();
      } else {
        data = {};
      }

      inputs.forEach((input) => {
        const name = input.name || input.id;
        const type = input.type;
        let value;

        if (type === "checkbox") {
          // if (input.checked) {
          if (data[input.name]) {
            value = `${data[input.name]},${
              input.value === "on" ? "true" : input.value
            }`;
          } else {
            value = input.checked;
            if (value === "on") value = "true";
          }
          if (input.getAttribute("is_key")) {
            data[input.value] = input.checked;
          }
          // } else {
          //   value = input.checked;
          //   return;
          // }
        } else if (type === "radio") {
          if (input.checked) {
            value = input.value;
          } else {
            return;
          }
        } else if (type === "select-multiple") {
          const selectedOptions = [...input.selectedOptions];
          const selectedValues = selectedOptions.map((option) => option.value);
          value = selectedValues.join(",");
        } else {
          value = type === "file" ? input.files[0] : input.value;
        }

        if (isFormData) {
          data.append(name, value);
        } else {
          data[name] = value;
        }
      });

      return data;
    },

    /**
     * Strips down email addresses from a string, removing unnecessary characters and invalid emails.
     *
     * @param {string} email - The email string to strip down.
     * @returns {string} Returns the stripped down email addresses separated by commas.
     */
    stripDownEmails: (email = "") => {
      if (!email || typeof email !== "string") return "";

      const emails = email
        .replace(/,/g, ";")
        .split(";")
        .map((s) => {
          const emailMatch = s.match(/<(.+)>/);
          const email = emailMatch ? emailMatch[1] : s;
          return email.trim();
        })
        .filter(
          (email) =>
            (email.includes("@") &&
              email.lastIndexOf(".") > email.indexOf("@")) ||
            email.includes("{")
        )
        .join(",");

      return emails || email;
    },

    /**
     * Checks if an element is visible.
     *
     * @param {HTMLElement} element - The element to check.
     * @returns {boolean} Returns true if the element is visible, false otherwise.
     */
    isVisible: (element) => {
      if (!element) return false;
      if (!(element instanceof HTMLElement)) return false;

      const style = getComputedStyle(element);
      if (style.display === "none") return false;
      if (style.visibility !== "visible") return false;
      if (style.opacity === "0") return false;

      const { width, height } = element.getBoundingClientRect();
      if (width === 0 || height === 0) return false;

      return true;
    },

    /**
     * Centers a modal element within its parent element.
     *
     * @param {object} options - The options for centering the modal.
     * @param {HTMLElement} options.parent - The parent element (default: document.documentElement).
     * @param {HTMLElement} options.child - The child element to center.
     */
    centerModal: ({ parent = document.documentElement, child }) => {
      if (!child) return;

      const root_w = parent.clientWidth;
      const root_h = parent.clientHeight;

      const centerX = root_w / 2 - parseInt(child.style.width, 10) / 2;
      const centerY = root_h / 2 - parseInt(child.style.height, 10) / 2;

      child.style.left = `${centerX < 0 ? 0 : centerX}px`;
      child.style.top = `${centerY < 0 ? 0 : centerY}px`;
    },

    /**
     * Filters out the visible elements from a list of elements.
     *
     * @param {NodeList} elements - The list of elements to filter.
     * @returns {Array} Returns an array containing the visible elements.
     */
    filterVisible: (elements) =>
      Array.from(elements).filter((el) => el.offsetParent !== null),

    /**
     * Retrieves the visible elements within a parent element based on a CSS selector.
     *
     * @param {HTMLElement} parent - The parent element to search within.
     * @param {string} selector - The CSS selector to match elements.
     * @returns {Array} Returns an array of visible elements matching the selector.
     */
    visibleElements: (parent, selector) =>
      filterVisible(parent.querySelectorAll(selector)),

    /**
     * Retrieves the value of a user customization based on the customization type.
     *
     * @param {string} customizationType - The type of user customization.
     * @returns {boolean} Returns true if the customization value is "true", false otherwise.
     */
    getUserCustomizationValue: (customizationType, value) => {
      const userCustomization =
        store.userInfo.userCustomizations?.[customizationType];
      if (userCustomization) {
        if (value) {
          return typeof userCustomization.value === "string"
            ? userCustomization.value
            : "";
        } else {
          return userCustomization.value === "true";
        }
      }
      if (value) {
        return null;
      }
      return false;
    },

    /**
     * Retrieves the DOM instance.
     *
     * @returns {object} Returns the DOM instance.
     */
    getDomInstance: () => {
      return _$;
    },

    // for new-upload
    formatBytes: function (bytes) {
      // if (!crush.isNumeric(bytes))
      //   return bytes;
      if (bytes < 0) return "*";
      if ((bytes / 1024).toFixed(0) == 0) return bytes.toFixed(1) + " " + "B";
      else if ((bytes / 1024 / 1024).toFixed(0) == 0)
        return (bytes / 1024).toFixed(1) + " " + "KB";
      else if ((bytes / 1024 / 1024 / 1024).toFixed(0) == 0)
        return (bytes / 1024 / 1024).toFixed(1) + " " + "MB";
      else if ((bytes / 1024 / 1024 / 1024 / 1024).toFixed(0) == 0)
        return (bytes / 1024 / 1024 / 1024).toFixed(1) + " " + "GB";
      else if ((bytes / 1024 / 1024 / 1024 / 1024 / 1024).toFixed(0) == 0)
        return (bytes / 1024 / 1024 / 1024 / 1024).toFixed(1) + " " + "TB";
    },

    //
    formatDateTime: (dateVal) => {
      if (!dateVal) return false;

      function padValue(value) {
        return value < 10 ? "0" + value : value;
      }

      let newDate = new Date(dateVal);

      let sMonth = padValue(newDate.getMonth() + 1);
      let sDay = padValue(newDate.getDate());
      let sYear = newDate.getFullYear();
      let sHour = newDate.getHours();
      let sMinute = padValue(newDate.getMinutes());
      let sAMPM = "AM";

      let iHourCheck = parseInt(sHour);

      if (iHourCheck > 12) {
        sAMPM = "PM";
        sHour = iHourCheck - 12;
      } else if (iHourCheck === 0) {
        sHour = "12";
      }

      sHour = padValue(sHour);

      return (
        sMonth +
        "/" +
        sDay +
        "/" +
        sYear +
        " " +
        sHour +
        ":" +
        sMinute +
        " " +
        sAMPM
      );
    },
    getAlternateDomains: () => {
      const altHttpDomains = store.userInfo.alt_http_domains;
      const serverNames = [];

      if (typeof altHttpDomains !== "string") return serverNames;

      let altDomains = altHttpDomains
        ? altHttpDomains.replace("alt_http_domains", "")
        : "";

      if (!altDomains) return serverNames;

      let altArr = [];

      if (altDomains.indexOf(";") >= 0) altArr = altDomains.split(";");
      else altArr = altDomains.split("\n");

      const port = window.location.port;
      const protocol = window.location.protocol;

      altArr.forEach((item) => {
        let serverName = item;

        if (!serverName) return;
        serverName = serverName.replace("\n", "");

        if (
          !serverName.startsWith("http://") &&
          !serverName.startsWith("https://")
        )
          serverName = protocol + "//" + serverName;
        if (serverName.endsWith("/")) serverName = serverName.slice(0, -1);
        if (serverName.split(":").length < 3) {
          serverName += ":" + port;
        }

        serverNames.push(serverName);
      });

      return serverNames;
    },

    titleAlert: (message = "", duration = 0) => {
      const previousTitle = document.title;

      document.title = message;
      setTimeout(() => {
        document.title = previousTitle;
      }, duration);
    },

    // Get file transfer (advanced downlaod and uploads) types availables with ranges in mb
    getFileTransferTypesRanges: (value) => {
      if (!value) return false;

      const ranges = value?.split("\n");

      return ranges?.map((range) => {
        const [from, to, type, method] = range?.split("|");
        return {
          from,
          to,
          type,
          method,
        };
      });
    },

    getTransferTypeForFileUsingRange: (
      ranges = [],
      fileSize,
      defaultType = "Normal"
    ) => {
      const mb = 1000000;
      const givenSize = parseInt(fileSize / mb);

      const selectedRange = ranges?.find((range) => {
        const from = parseInt(range?.from);
        const to = parseInt(range?.to);

        if ((!from || givenSize >= from) && (!to || givenSize <= to)) {
          return true;
        }

        return false;
      });

      if (selectedRange?.method) {
        return selectedRange?.type + "|" + selectedRange?.method;
      }

      return selectedRange?.type || defaultType;
    },

    filterLanguagesToShow: (values) => {
      const languages = values?.split(",");
      console.log("Languages to show:" + values);
      const languagesOptions = _$("w-language").find("option").sel();

      languagesOptions.forEach((language) => {
        const languageName = languagesWithCode[language.value];
        const canShowThisLanguage = languages.includes(languageName);

        if (!canShowThisLanguage) {
          language.parentNode.removeChild(language);
        }
      });
    },
    doWeSupportThisLanguage: (language) => {
      return languagesWithCode[language] ? true : false;
    },

    generatePassword: (length = 8) => {
      const charset =
        "abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
      let retVal = "";
      for (var i = 0, n = charset.length; i < length; ++i) {
        retVal += charset.charAt(Math.floor(Math.random() * n));
      }
      return retVal;
    },

    generatePasswordBasedOnRules: (passwordRule = window.passwordRule) => {
      const maxChars = passwordRule?.random_password_length
        ? parseInt(passwordRule.random_password_length)
        : 8;
      const minNumeric = passwordRule?.min_password_numbers
        ? parseInt(passwordRule.min_password_numbers)
        : 0;
      const minLower = passwordRule?.min_password_lowers
        ? parseInt(passwordRule.min_password_lowers)
        : 0;
      const minUpper = passwordRule?.min_password_uppers
        ? parseInt(passwordRule.min_password_uppers)
        : 0;
      const minSpecial = passwordRule?.min_password_specials
        ? parseInt(passwordRule.min_password_specials)
        : 0;
      const unsafeChars = passwordRule?.unsafe_password_chars || "";

      const specialChars = "$^&*()_-+=[]{};,.<>?~"
        .split("")
        .filter((char) => !unsafeChars.includes(char))
        .join("");

      const randomPass = (length = maxChars, numeric = false, possible) => {
        possible =
          possible || "ABCDEFGHKMNPQRTUVWXYZabcdefghkmnpqrtuvwxyz123467890";
        if (numeric) {
          possible = "123467890";
        }
        let randomId = "";
        for (let i = 0; i < length; i++) {
          randomId += possible.charAt(
            Math.floor(Math.random() * possible.length)
          );
        }
        return randomId;
      };

      let passwords = [];
      if (minNumeric > 0) {
        passwords.push(randomPass(minNumeric, true));
      }
      if (minLower > 0) {
        passwords.push(randomPass(minLower, false, "abcdefghkmnpqrtuvwxyz"));
      }
      if (minUpper > 0) {
        passwords.push(randomPass(minUpper, false, "ABCDEFGHKMNPQRTUVWXYZ"));
      }
      if (minSpecial > 0) {
        passwords.push(randomPass(minSpecial, false, specialChars));
      }

      passwords.sort(() => Math.random() - 0.5);
      let pass = passwords
        .join("")
        .split("")
        .sort(() => Math.random() - 0.5)
        .join("");
      if (pass.length > maxChars) {
        pass = pass.substr(0, maxChars);
      } else if (pass.length < maxChars) {
        pass += randomPass(maxChars - pass.length);
      }
      pass = pass
        .split("")
        .sort(() => Math.random() - 0.5)
        .join("");
      return pass;
    },

    makeResizable: ({
      elem,
      onDrag = () => {},
      onSelect = () => {},
      onDrop = () => {},
      cornersVisible = true,
      enableCorners = { left: true, right: true, top: true, bottom: true },
      stopDragOnCondition = () => {},
    }) => {
      if (!elem) return;

      elem.insertAdjacentHTML(
        "beforeend",
        resizableTemplate({ ...enableCorners })
      );

      const _elem = _$(elem);
      _elem.addClass("is-relative");

      _elem.find(".resizable-corners").on("click", (e) => {
        e.stopPropagation();
      });

      _elem.find(".resizable-corners").on("mouseup", (e) => {
        e.stopPropagation();
      });

      window.addEventListener("dragend", () => {
        window.removeEventListener("mousemove", mouseMoveEvent);
      });

      if (cornersVisible) {
        _elem.find(".resizable-wrapper").addClass("always-visible");
      }

      const mouseMoveEvent = (e) => {
        const { left } = elem.getBoundingClientRect();
        const cursorX = e.pageX;

        if (cursorX < left) {
          return;
        }

        const widthLeft = Math.abs(cursorX - left);

        if (stopDragOnCondition({ width: widthLeft })) {
          return;
        }

        _elem.find(".resizable-wrapper").el().style.width = `${widthLeft}px`;
        onDrag({ width: widthLeft });
      };

      const mouseUpEvent = (e) => {
        _elem.find(".resizable-corners.active").removeClass("active");
        window.removeEventListener("mousemove", mouseMoveEvent);
        window.removeEventListener("mouseup", mouseUpEvent);

        const width = _elem.find(".resizable-wrapper").el().offsetWidth;
        onDrop({ width });
      };

      const mouseDownEvent = (e) => {
        _$(e.currentTarget).addClass("active");
        window.addEventListener("mousemove", mouseMoveEvent);
        window.addEventListener("mouseup", mouseUpEvent);
      };

      _elem.find(".resizable-right").on("mousedown", (e) => {
        e.stopPropagation();
        mouseDownEvent(e);
      });
    },

    sendBrowserNotification: ({ onPermissionGranted = () => {} }) => {
      if (!("Notification" in window)) {
        // Check if the browser supports notifications
        alert("This browser does not support desktop notification");
      } else if (Notification.permission === "granted") {
        // Check whether notification permissions have already been granted;
        // if so, create a notification
        onPermissionGranted();
        // …
      } else if (Notification.permission !== "denied") {
        // We need to ask the user for permission
        Notification.requestPermission().then((permission) => {
          // If the user accepts, let's create a notification
          if (permission === "granted") {
            onPermissionGranted();
            // …
          }
        });
      }

      // At last, if the user has denied notifications, and you
      // want to be respectful there is no need to bother them anymore.
    },

    getElementsInRange: ({ startElement, endElement, elements }) => {
      if (!startElement || !endElement || !elements) {
        return [];
      }

      if (startElement === endElement) {
        return [];
      }

      const elementsInRange = [];
      let begin = false;

      elements.every((ele) => {
        if (ele === startElement || ele === endElement) {
          if (begin) {
            return false;
          }

          begin = true;
          return true;
        }

        if (begin) {
          elementsInRange.push(ele);
        }
        return true;
      });

      return elementsInRange;
    },

    dateStringFormat: ({ format = "", fromManageShares = false }) => {
      if (!format) {
        return "";
      }

      let replacableFormats = {
        MM: "m",
        dd: "d",
        yyyy: "Y",
        yy: "y",
        hh: "G",
        HH: "H",
        mm: "i",
        ss: "S",
        aa: "K",
      };

      if (fromManageShares) {
        replacableFormats = {
          ...replacableFormats,
          mm: "m",
          MM: "",
          nn: "i",
          "a/p": "K",
        };
      }

      let str = format;
      for (const [key, value] of Object.entries(replacableFormats)) {
        str = str.replaceAll(key, value);
      }

      return str;
    },

    countSubscribedEventsTags: (str) => {
      if (!str) {
        return 0;
      }

      const tagPattern = /\((.*?)\)/g;
      const tags = str.match(tagPattern);
      return tags ? tags.length : 0;
    },
  };
};
window.unescape = window.unescape || window.decodeURI;
window.utils = utilsFunc();
